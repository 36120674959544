import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import get from 'lodash-es/get';

import { confirmEmail } from '../state/actions/identityActions';
import FullPageSpinnerContainer from '../components/SessionCheck/FullPageSpinnerContainer';
import Spinner from '../components/SessionCheck/Spinner';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContainer from '../components/layout-components/PageContainer';
import MainCol from '../components/PageBody/MainCol';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import JumbotronBlock from '../components/JumbotronBlock';
import BlockStyles from '../components/BlockStyles';
import { PrismicRichText } from '@prismicio/react';
import ButtonLink from '../components/ButtonLink';
import AuthenticatedWrapper from '../components/Community/AuthenticatedWrapper';

const Body = styled.div`
  h2 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 24px;
  }

  p,
  ul {
    margin-left: 4px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 64px;
`;

const propTypes = { data: PropTypes.object, location: PropTypes.object };

const defaultProps = { data: {}, location: {} };

function EmailConfirmation({ data: queryResult, location }) {
  const data = get(queryResult, 'prismicCommunityEmailConfirmation.data');
  if (!data) {
    return null;
  }

  const queryParams = new URLSearchParams(location.search);
  const confirmationToken = queryParams.get('confirmation_token');

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState({});

  const {
    banner_image,
    page_title,
    confirmed_text,
    button_link,
    button_text,
    failed_text
  } = data;

  useEffect(() => {
    confirmEmail(confirmationToken).then((result) => {
      setResult(result);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <FullPageSpinnerContainer>
      <Spinner />
    </FullPageSpinnerContainer>
  ) : (
    <AuthenticatedWrapper location={location}>
      <Layout>
        <JumbotronBlock
          title={page_title.text}
          url={banner_image && banner_image.url}
        />
        <PageContainer>
          <MainCol>
            <ColumnWrapper>
              <BlockStyles>
                <Body>
                  {result.success ? (
                    <PrismicRichText field={confirmed_text.richText} />
                  ) : (
                    <>
                      <PrismicRichText field={failed_text.richText} />
                      <ul>
                        {result.errors.map((error) => (
                          <li key={error}>{error}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  <ButtonContainer>
                    <ButtonLink
                      link={button_link}
                      fontWeight='semiBold'
                      queryParameters={result.success ? { linked: true } : null}
                      size={200}
                    >
                      {button_text}
                    </ButtonLink>
                  </ButtonContainer>
                </Body>
              </BlockStyles>
            </ColumnWrapper>
          </MainCol>
        </PageContainer>
      </Layout>
    </AuthenticatedWrapper>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicCommunityEmailConfirmation.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <>
      <meta name='robots' content='noindex' />
      <SEO
        title={seo_title || page_title.text}
        description={seo_meta_description}
        image={seo_image}
      />
    </>
  );
};

export const query = graphql`
  query EmailConfirmationQuery($id: String) {
    prismicCommunityEmailConfirmation(id: { eq: $id }) {
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        banner_image {
          url
          dimensions {
            height
            width
          }
          alt
        }
        button_link {
          uid
          type
          url
          target
        }
        button_text
        failed_text {
          richText
          text
        }
        confirmed_text {
          richText
          text
        }
        page_title {
          richText
          text
        }
      }
    }
  }
`;

EmailConfirmation.propTypes = propTypes;
EmailConfirmation.defaultProps = defaultProps;

export default EmailConfirmation;
