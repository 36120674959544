import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const StyledJumbotron = styled.div`
  align-items: center;
  border-radius: 0;
  background-color: ${prop('theme.colors.white')};
  background-image: url('${prop('url')}');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  display: none;
  height: 5.75rem;
  justify-content: left;
  margin-bottom: 0;
  padding: 1.8rem 1rem;
  position: relative;
  
  ${ifProp(
    '$displaySideNav',
    css`
      margin-top: 3rem;
    `
  )}

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    height: 8.8rem;
    padding: 2.5rem 0;
    display: flex;
    align-items: center;
    height: 144px;
    background-position: 10%;
  }
  
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 0;
    margin-bottom: 2rem;
    background-position: 15%;
  }
`;

const JumbotronHeading = styled.h1`
  color: ${prop('theme.colors.titleGrey')};
  font-family: ${prop('theme.fonts.default')};
  font-weight: ${prop('theme.fontWeights.semiBold')};
  line-height: normal;
  margin-bottom: 0;
  font-size: 1.5rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    font-size: 2.25rem;
    margin-left: 14.75rem;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-left: 22%;
    font-size: 2.5rem;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    margin-left: 20.125rem;
  }
`;

const Divider = styled.span`
  color: ${prop('theme.colors.redPrimary')};
  padding: 0 0.625rem;
`;

const propTypes = {
  embedVideo: PropTypes.object,
  symbol: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string,
  displaySideNav: PropTypes.bool
};

const defaultProps = {
  symbol: '',
  title: '',
  url: '',
  displaySideNav: true
};

function JumbotronBlock({ symbol, title, url, displaySideNav = true }) {
  // Some products have a registered icon after the symbol, and we want to display that as superscript
  const registeredIndex = symbol.indexOf('®');
  let displaySymbol = symbol;
  let shouldDisplayRegisteredIcon = false;
  if (registeredIndex > -1) {
    displaySymbol = displaySymbol.substr(0, registeredIndex);
    shouldDisplayRegisteredIcon = true;
  }

  return (
    <StyledJumbotron url={url} $displaySideNav={displaySideNav}>
      <JumbotronHeading>
        {displaySymbol ? (
          <>
            {displaySymbol}
            {shouldDisplayRegisteredIcon && <sup>®</sup>}
            <Divider>\</Divider>
            {title}
          </>
        ) : (
          title
        )}
      </JumbotronHeading>
    </StyledJumbotron>
  );
}

JumbotronBlock.propTypes = propTypes;
JumbotronBlock.defaultProps = defaultProps;

export default JumbotronBlock;
